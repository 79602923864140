import React from 'react'
import autogiro from '../images/bankid-screenshot.png'
import Layout from '../components/layout'

import AutogiroBrevPDF from '../images/autogirobrev-Lantmannen.pdf'
const url =
  'https://www.mvh.bgonline.se/mandate/282f94f5-5945-4e8f-88a3-fa5dfc3743fc'

const Button = () => (
  <a
    class="button"
    style={{ color: '#d42a2a' }}
    target="_blank"
    rel="noreferrer"
    href={AutogiroBrevPDF}
  >
   Klicka här för att öppna e-formuläret.
  </a>
)


const AutogiroPage = () => (
  <Layout articlelevel={100}>
    <>
      <div className="contentWrapper flex">
        <div
          className="content content--inverted"
          style={{ textAlign: 'left' }}
        >
          <h1>Betala din prenumeration enkelt med autogiro</h1>
          <p>
          Autogiro är ett smidigt sätt att betala din prenumeration utan att behöva hålla koll på avier och förfallodatum. 
          Beloppet dras automatiskt från ditt konto den 28:e varje månad.
          </p>
          <h3 className="roboto">För att komma igång behöver du:</h3>
          <p>
              <b>Kundnummer:</b> Det hittar du på din faktura eller genom att logga in på <a href="https://minasidor.lantmannen.nu" className="whiteText" target="_blank">Mina sidor</a>. Om du inte har tillgång till ditt kundnummer är du välkommen att kontakta vår kundservice så hjälper vi dig vidare.
              <br />
              <b>BankID:</b> För att signera ditt medgivande.
          </p>
          <h3 className="roboto">Slutför registreringen i tre enkla steg:</h3>
          <p>
              1. Gå till vårt e-formulär.
              <br /> 
              2. Fyll i dina uppgifter och läs igenom villkoren.
              <br/>
              3. Signera enkelt med BankID genom att följa instruktionerna.
          </p>
          <h3 className="roboto">Prislista:</h3>
          <p>
              Lantmannen Total - 176:-/mån
              <br /> 
              Lantmannen Papper - 143:-/mån
              <br/>
              Lantmannen Digital - 95:-/mån
              <br/>
              <i>Samtliga priser exkl. 6% moms</i>
          </p>
          <p>
            <Button />
          </p>
          <p>
          Har du frågor eller funderingar? Hör av dig till vår <a href="mailto:kundservice@lrfmedia.se" className="whiteText">kundservice</a> - vi hjälper dig gärna!
          </p>
        </div>
      </div>
    </>
  </Layout>
)

export default AutogiroPage
